<template>
  <div class="flex-grow">
    <div class="flex items-center justify-center h-full">
      <div class="login-wrapper">
        <Form @submit="handleLogin" :validation-schema="schema">
          <div class="hong-card card-green m-auto pb-2 pt-1 px-4 text-white" style="min-width: 24rem">
            <div class="card-body">
              <h5 class="mb-3 text-center text-3xl font-black">เข้าสู่ระบบ</h5>
              <div class="mb-3">
                <Field name="username" type="text" class="form-control" placeholder="กรอกเบอร์โทรศัพท์ของท่าน" />
                <ErrorMessage name="username" class="error-feedback danger" />
              </div>
              <div class="mb-3">
                <Field name="password" type="password" class="form-control" placeholder="กรอกรหัสผ่านของท่าน" />
                <ErrorMessage name="password" class="error-feedback danger" />
              </div>
              <div class="d-grid gap-2 mt-3">
                <button class="btn btn-secondary btn-lg" :disabled="loading">
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>เข้าสู่ระบบ</span>
                </button>
                <div class="form-group">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
              </div>

              <div class="mb-3 mt-3"><router-link to="/forgetpassword">ลืมรหัสผ่าน</router-link></div>
              <div class="mb-3 mt-3"><hr /></div>
              <div class="d-grid gap-2 mt-3">
                <!-- <button type="submit" class="btn btn-secondary btn-lg">สร้างบัญชีผู้ใช้</button> -->
                <router-link to="/register" class="btn btn-secondary btn-lg">สร้างบัญชีผู้ใช้</router-link>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required('username is required!'),
      password: yup.string().required('Password is required!')
    })

    return {
      loading: false,
      message: '',
      schema
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile')
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true

      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$router.push('/profile')
        },
        (error) => {
          this.loading = false
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        }
      )
    }
  }
}
</script>
<style scoped>
a {
  color: #fff;
}
</style>
